export enum ConnectedStatusEnum {
  VALIDATED = 0,
  MIGRATION = 1,
  WEB = 2,
  MOBILE = 3,
  NON_VERIFIE_WEB = 4
}

export namespace ConnectedStatusEnum {
  export function format(connectedStatus: number) {
    switch (ConnectedStatusEnum[connectedStatus]) {
      case 'VALIDATED':
        return 'Vérifié';
      case 'MIGRATION':
        return 'Migration';
      case 'WEB':
        return 'Web';
      case 'MOBILE':
        return 'Mobile';
      case 'NON_VERIFIE_WEB':
        return 'Non-vérifié Web';
      default:
        return '';
    }
  }
}
