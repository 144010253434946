const la100Stacked = 'la100% Stacked';
const laStacked = 'laStacked';
const laGrouped = 'laGrouped';
const laBar = 'laBar';
const laPie = 'laPie';
const laScatter = 'laScatter';
const laBubble = 'laBubble';
const laArea = 'laArea';
export const agGridLanguageFr = {
  // for filter panel
  page: 'Page',
  more: 'Plus',
  to: 'à',
  of: 'sur',
  next: 'Suivant',
  last: 'Dernier',
  first: 'Premier',
  previous: 'Précédent',
  loadingOoo: 'Chargement...',

  // for set filter
  selectAll: 'Tout sélectionner',
  searchOoo: 'Recherche...',
  blanks: 'blanc',

  // for number filter and text filter
  filterOoo: 'Filtre...',
  applyFilter: '...',
  equals: 'Egal',
  notEqual: 'Différent',

  // for number filter
  lessThan: 'Inférieur à',
  greaterThan: 'Supérieur à',
  lessThanOrEqual: 'Inférieur ou égal à',
  greaterThanOrEqual: 'Supérieur ou égal à',
  inRange: "Dans l'intervalle",

  // for text filter
  contains: 'Contient',
  notContains: 'Ne contient pas',
  startsWith: 'Commence par',
  endsWith: 'Fini avec',

  // filter conditions
  andCondition: 'Et',
  orCondition: 'Et/Ou',

  // the header of the default group column
  group: 'Groupe',

  // tool panel
  columns: 'Colonne',
  filters: 'Filtres',
  rowGroupColumns: 'laPivot Cols',
  rowGroupColumnsEmptyMessage: 'la drag cols to group',
  valueColumns: 'laValue Cols',
  pivotMode: 'laPivot-Mode',
  groups: 'Groupes',
  values: 'Valeurs',
  pivots: 'laPivots',
  valueColumnsEmptyMessage: 'Déplacer ici la colonne',
  pivotColumnsEmptyMessage: 'la drag here to pivot',
  toolPanelButton: 'Tools',

  // other
  noRowsToShow: 'Rien à afficher',
  enabled: 'Activer',

  // enterprise menu
  pinColumn: 'Pin',
  valueAggregation: 'Agrégation',
  autosizeThiscolumn: 'Auto dimensionner cette colonne',
  autosizeAllColumns: 'Auto dimensionner',
  groupBy: 'Grouper par',
  ungroupBy: 'Dégrouper par',
  resetColumns: 'laReset Those Cols',
  expandAll: 'Tout ouvrir',
  collapseAll: 'Tout fermer',
  toolPanel: 'Tools',
  export: 'Export',
  csvExport: 'Export CSV',
  excelExport: 'Export Excel (.xlsx)',
  excelXmlExport: 'Export XML (.xml)',

  // enterprise menu (charts)
  pivotChartAndPivotMode: 'laPivot Chart & Pivot Mode',
  pivotChart: 'laPivot Chart',
  chartRange: 'laChart Range',

  columnChart: 'Colonne',
  groupedColumn: 'Grouper',
  stackedColumn: laStacked,
  normalizedColumn: la100Stacked,

  barChart: laBar,
  groupedBar: laGrouped,
  stackedBar: laStacked,
  normalizedBar: la100Stacked,

  pieChart: laPie,
  pie: laPie,
  doughnut: 'laDoughnut',

  line: 'Ligne',

  xyChart: 'laX Y (Scatter)',
  scatter: laScatter,
  bubble: laBubble,

  areaChart: laArea,
  area: laArea,
  stackedArea: laStacked,
  normalizedArea: la100Stacked,

  // enterprise menu pinning
  pinLeft: 'laPin <<',
  pinRight: 'laPin >>',
  noPin: 'laDontPin <>',

  // enterprise menu aggregation and status bar
  sum: 'laSum',
  min: 'laMin',
  max: 'laMax',
  none: 'laNone',
  count: 'laCount',
  average: 'laAverage',
  filteredRows: 'laFiltered',
  selectedRows: 'laSelected',
  totalRows: 'laTotal Rows',
  totalAndFilteredRows: 'laRows',

  // standard menu
  copy: 'Copier',
  copyWithHeaders: "Copier avec l'entête",
  ctrlC: 'CTRL+C',
  paste: 'Coller',
  ctrlV: 'CTRL+V',

  // charts
  pivotChartTitle: 'laPivot Chart',
  rangeChartTitle: 'laRange Chart',
  settings: 'laSettings',
  data: 'laData',
  format: 'laFormat',
  categories: 'laCategories',
  series: 'laSeries',
  xyValues: 'laX Y Values',
  paired: 'laPaired Mode',
  axis: 'laAxis',
  color: 'laColor',
  thickness: 'laThickness',
  xRotation: 'laX Rotation',
  yRotation: 'laY Rotation',
  ticks: 'laTicks',
  width: 'laWidth',
  length: 'laLength',
  padding: 'laPadding',
  chart: 'laChart',
  title: 'laTitle',
  background: 'laBackground',
  font: 'laFont',
  top: 'laTop',
  right: 'laRight',
  bottom: 'laBottom',
  left: 'laLeft',
  labels: 'laLabels',
  size: 'laSize',
  minSize: 'laMinimum Size',
  maxSize: 'laMaximum Size',
  legend: 'laLegend',
  position: 'laPosition',
  markerSize: 'laMarker Size',
  markerStroke: 'laMarker Stroke',
  markerPadding: 'laMarker Padding',
  itemPaddingX: 'laItem Padding X',
  itemPaddingY: 'laItem Padding Y',
  strokeWidth: 'laStroke Width',
  offset: 'laOffset',
  offsets: 'laOffsets',
  tooltips: 'laTooltips',
  callout: 'laCallout',
  markers: 'laMarkers',
  shadow: 'laShadow',
  blur: 'laBlur',
  xOffset: 'laX Offset',
  yOffset: 'laY Offset',
  lineWidth: 'laLine Width',
  normal: 'laNormal',
  bold: 'laBold',
  italic: 'laItalic',
  boldItalic: 'laBold Italic',
  predefined: 'laPredefined',
  fillOpacity: 'laFill Opacity',
  strokeOpacity: 'laLine Opacity',
  columnGroup: 'laColumn',
  barGroup: laBar,
  pieGroup: laPie,
  lineGroup: 'laLine',
  scatterGroup: laScatter,
  areaGroup: laArea,
  groupedColumnTooltip: laGrouped,
  stackedColumnTooltip: laStacked,
  normalizedColumnTooltip: la100Stacked,
  groupedBarTooltip: laGrouped,
  stackedBarTooltip: laStacked,
  normalizedBarTooltip: la100Stacked,
  pieTooltip: laPie,
  doughnutTooltip: 'laDoughnut',
  lineTooltip: 'laLine',
  groupedAreaTooltip: laGrouped,
  stackedAreaTooltip: laStacked,
  normalizedAreaTooltip: la100Stacked,
  scatterTooltip: laScatter,
  bubbleTooltip: laBubble,
  noDataToChart: 'laNo data available to be charted.',
  pivotChartRequiresPivotMode: 'laPivot Chart requires Pivot Mode enabled.'
};
