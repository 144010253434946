import {Component, OnDestroy} from '@angular/core';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styleUrls: ['./dialog-confirm.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogTitle, MatDialogContent, MatDialogActions]
})
export class DialogConfirmComponent implements OnDestroy {
  result: boolean;
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<DialogConfirmComponent>) {}

  close() {
    this.result = true;
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
}
